.container {
    max-width: 1200px;
    margin: 35px auto;
    padding: 2rem 1rem;
    border: 1px solid #008c0d;
    border-radius: 15px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .header h1 {
    color: #1e40af;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .header p {
    color: #64748b;
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .tableContainer {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid #e2e8f0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  }
  .row:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .row:last-child {
    border-bottom: none;
  }
  
  .specGroup {
    display: flex;
    gap: 2rem;
    align-items: center;
    flex: 1;
  
  }
  
  @media (max-width: 1024px) {
    .specGroup {
      gap: 1rem;
      flex-wrap: wrap;
    }
  
    .row {
      flex-direction: column;
      gap: 1rem;
    }
  
    .pricing {
      width: 100%;
      justify-content: space-between;
    }
  }
  
  .spec {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .icon,
  .memoryIcon,
  .storageIcon,
  .networkIcon {
    color: #64748b;
  }
  
  .primary {
    font-weight: 500;
    color: #1e293b;
  }
  
  .secondary {
    font-size: 0.875rem;
    color: #64748b;
  }
  
  .pricing {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .priceGroup {
    text-align: right;
  }
  
  .price {
    font-weight: 600;
    color: #1e293b;
  }
  
  .setupFee {
    font-size: 0.875rem;
    color: #64748b;
  }
  
  .configureButton {
    background-color: #1e40af;
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .configureButton:hover {
    background-color: #1e3a8a;
  }
  
  @media (max-width: 768px) {
    .header h1 {
      font-size: 2rem;
    }
  
    .header p {
      font-size: 1rem;
    }
  
    .specGroup {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  
    .spec {
      width: 100%;
    }
  
    .pricing {
      flex-direction: column;
      align-items: stretch;
      gap: 1rem;
    }
  
    .priceGroup {
      text-align: left;
    }
  
    .configureButton {
      width: 100%;
    }
  }
  
  