.blogContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .mainTitle {
    font-size: 2.5rem;
    color: #008c0d;
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }
  
  .featuredImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 2rem 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .section {
    margin: 2rem 0;
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .sectionTitle {
    font-size: 2rem;
    color: #008c0d;
    margin-bottom: 1.5rem;
  }
  
  .highlight {
    color: #008c0d;
    font-weight: 600;
  }
  
  .list {
    padding-left: 2rem;
    margin: 1.5rem 0;
  }
  
  .list li {
    margin: 1rem 0;
    padding-left: 0.5rem;
  }
  
  .step {
    margin: 2rem 0;
  }
  
  .stepTitle {
    font-size: 1.5rem;
    color: #008c0d;
    margin-bottom: 1rem;
  }
  
  .faqSection {
    background: #f8fafc;
    border-left: 4px solid #4299e1;
  }
  
  .faqItem {
    margin: 2rem 0;
  }
  
  .faqQuestion {
    color: #008c0d;
    margin-bottom: 0.5rem;
  }
  
  .ctaSection {
    text-align: center;
    background: #f0f4f8;
    padding: 3rem !important;
  }
  
  .ctaButton {
    background-color: #008c0d;
    color: white;
    padding: 1rem 2.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
  }
  
  .ctaButton:hover {
    background-color: #00700b;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .blogContainer {
      padding: 1rem;
    }
  
    .mainTitle {
      font-size: 2rem;
    }
  
    .sectionTitle {
      font-size: 1.75rem;
    }
  
    .stepTitle {
      font-size: 1.3rem;
    }
  
    .section {
      padding: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .mainTitle {
      font-size: 1.75rem;
    }
  
    .ctaButton {
      width: 100%;
      padding: 1rem;
    }
  }