/* Reset and base styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
    line-height: 1.5;
    color: #333;
    background-color: #f5f5f5;
  }
  
  /* Pricing container */
  .pricing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 48px 16px;
  }
  
  /* Pricing header */
  .pricing-header {
    text-align: center;
    margin-bottom: 48px;
  }
  
  .pricing-header h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }
  
  .pricing-header p {
    font-size: 1.1rem;
    color: #666;
  }
  
  /* Pricing grid */
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 24px;
  }
  
  /* Pricing card */
  .pricing-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    display: flex
;
    flex-direction: column;
    justify-content: space-between;
}


  
  
  .pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Best seller badge */
  .best-seller {
    position: absolute;
    top: -3px;
    right: 0px;
    background-color: #4caf50;
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 4px 12px;
    border-radius: 9999px;
    z-index: 1;
  }
  
  /* Card header */
  .card-header-home {
    padding: 32px 24px;
    text-align: center;
    color: #fff;
  }
  
  .card-header h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  
  .price {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1;
  }
  
  .currency {
    font-size: 1.5rem;
    vertical-align: top;
    margin-right: 2px;
  }
  
  .amount {
    font-size: 3rem;
  }
  
  .cents {
    font-size: 1.5rem;
    vertical-align: top;
  }
  
  .period {
    font-size: 1rem;
    font-weight: normal;
  }
  
  /* Card content */
  .card-content {
    padding: 24px;
  }
  
  .card-content ul {
    list-style-type: none;
  }
  
  .card-content li {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 0.9rem;
    color: #666;
  }
  .card-content li svg{
    padding-right: 3px ;
    color: #16a34a;
  }
  
  .checkmark {
    color: #4caf50;
    margin-right: 8px;
    font-weight: bold;
  }
  
  /* Card footer */
  .card-footer {
    padding: 24px;
  }
  
  .select-plan {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Color schemes */
  .slate .card-header-home,
  .slate .select-plan {
    background-color: #475569;
  }
  .sky .card-header-home,
  .sky .select-plan {
    background-color: #0ea5e9;
  }
  .green .card-header-home,
  .green .select-plan {
    background-color: #22c55e;
  }
  .orange .card-header-home,
  .orange .select-plan {
    background-color: #f97316;
  }
  
  .slate .select-plan:hover {
    background-color: #334155;
  }
  .sky .select-plan:hover {
    background-color: #0284c7;
  }
  .green .select-plan:hover {
    background-color: #16a34a;
  }
  .orange .select-plan:hover {
    background-color: #ea580c;
  }
  
  /* Responsive design */
  @media (max-width: 1024px) {
    .pricing-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .pricing-grid {
      grid-template-columns: 1fr;
    }
  
    .pricing-header h2 {
      font-size: 2rem;
    }
  
    .pricing-header p {
      font-size: 1rem;
    }
  
    .card-header h3 {
      font-size: 1.25rem;
    }
  
    .price {
      font-size: 2.5rem;
    }
  
    .currency,
    .cents {
      font-size: 1.25rem;
    }
  }
  
  @media (max-width: 480px) {
    .pricing-container {
      padding: 32px 16px;
    }
  
    .pricing-header {
      margin-bottom: 32px;
    }
  
    .card-header {
      padding: 24px 16px;
    }
  
    .card-content,
    .card-footer {
      padding: 16px;
    }
  }
  
  