.footer {
  background-color: #2c3e50;
  color: white;
  padding: 30px 40px;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  align-items: start;
  text-align: left;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer-column h3 {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  margin-bottom: 10px;
  justify-content: start;
  color: #008c0d;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.footer-column ul li a {
  color: white;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 5px;
}
.footer-column ul li a:hover{
  color: #008c0d;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-column.large {
  grid-column: span 1;
  row-gap: 15px;
}

@media (max-width: 1024px) {
  .footer-container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
  .footer-column.large {
    grid-column: span 1;
  }
}

@media (max-width: 600px) {
  .footer-container {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .footer-column {
    align-items: center;
  }
  .footer-column h3 {
    justify-content: center;
  }
  .footer-column ul li {
    justify-content: center;
  }
}


.footer-bottom{
 display: flex;
 align-items: center;
 justify-content: space-between;
 width: 100%;
 height: 60px;
 background-color: #000;
 padding: 10px;

}
.footer-bottom h2{
font-size: 15px;
color: white;
margin-bottom: 0px;
}
.footer-socialicons .large-social-icons .social-icons{
  padding: 0px;
  color: white;

}
.footer-socialicons .large-social-icons .social-icons svg{
  padding: 0px;
  color: white;

}
.footer-socialicons .large-social-icons .social-icons .icon{
  padding: 0px;
  color: white;
  font-size: 28px;

}
.footer-socialicons .large-social-icons{
  left: 0px;
}

.footer-socialicons .large-social-icons .whatsapp:hover svg{
  color: #25d366; /* WhatsApp green */
}

.footer-socialicons .large-social-icons .facebook:hover svg {
  color: #3b5998; /* Facebook blue */
}

.footer-socialicons .large-social-icons .twitter:hover svg {
  color: #1da1f2; /* Twitter blue */
}

.footer-socialicons .large-social-icons .instagram:hover svg {
  color: #e1306c; /* Instagram pink */

}
@media (max-width:715px) {
  .footer-bottom h2{
    font-size: 12px;
  }
}
@media (max-width:600px) {
  .footer-bottom h2{
    font-size: 10px;
  }
  .footer-socialicons .large-social-icons .social-icons .icon{
    padding: 0px;
    color: white;
    font-size: 20px;
  
  }
}
@media (max-width:500px) {
  .footer-bottom h2{
    font-size: 7px;
  }
  .footer-socialicons .large-social-icons .social-icons .icon{
    padding: 0px;
    color: white;
    font-size: 14px;
  
  }
}
@media (max-width:400px) {
  .footer-bottom h2{
    font-size: 6px;
  }
  .footer-socialicons .large-social-icons .social-icons .icon{
    padding: 0px;
    color: white;
    font-size: 13px;
  
  }
}