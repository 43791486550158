@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
  --theme-color: #008c0d; 

}
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #00c800;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #005f07;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #008c0d #f1f1f1;
}

.headerwrapper.hidden {
  display: none;
}

@media (max-width: 768px) {
  .headerwrapper {
    display: none;
  }
}

.number-bar{
  width: 100%;
  height: 70px;
  background-color: #006b07;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.number-bar svg{
  font-size: 50px;
  color: #ffff;
}
.number-bar p{
  font-size: 30px;
  color: #ffff;
}
.number-bar p:nth-child(2){
  font-size: 20px;
  color: #ffff;
}


@media (max-width:905px){
  .number-bar p{
    font-size: 20px;

  }
  .number-bar p:nth-child(2){
    font-size: 15px;
 
  }
}
@media (max-width:653px){
  .number-bar p{
    font-size: 11px;
  
  }
  .number-bar svg{
    font-size: 40px;
  
  }
  .number-bar p:nth-child(2){
    font-size: 7px;

  }
}
*{
  padding: 0px ;
  margin: 0 ;
  box-sizing: border-box !important;
  font-family: "Poppins", serif !important;
}
p{
  margin-bottom: 0px !important;
}
.headerwrapper .header-strip{
  position: fixed;
}

.headerwrapper .header-strip{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 17px;
  background-color: 
  #06ae16;
  padding: 15px !important;
  font-size: 13px;
  color:#ffffff;
  font-weight: 600px;
  

  
}
.headerwrapper .header-strip p{
  font-size: 16px;
}
.headerwrapper .header-strip .email svg{font-size:18px ;}
.headerwrapper .header-strip  svg{border: rgba(0,0,0,0.4);color:#fff;}

.headerwrapper .header-strip .social-icons ul li a {
  padding: 3px 10px;
  margin-bottom: 2px;
  font-size: 20px;
  border: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: color 0.3s ease; /* Smooth transition for hover */
  /* padding-bottom: 2px !important; */
   position: relative;
   bottom: 2px;
}

.headerwrapper .header-strip .social-icons li:nth-child(1):hover a svg {
  color: #4267B2; /* Facebook blue */
}

.headerwrapper .header-strip .social-icons li:nth-child(2):hover a svg {
  color: #1DA1F2; /* Twitter blue */
}

.headerwrapper .header-strip .social-icons li:nth-child(3):hover a svg {
  color: #C13584; /* Instagram gradient pink */
}

.headerwrapper .header-strip .tsupport:hover *{
  color: #f1f1f1c9;

}
.headerwrapper .header-strip .num:hover *{
  color: #f1f1f1c9;

}
.headerwrapper .header-strip .email:hover *{
  color: #f1f1f1c9;
}
.headerwrapper .header-strip  .num svg 
{
  font-size: 20px;
}


/* Responsive Adjustments */
/* 
/* For Mobile Devices */
@media only screen and (max-width: 742px) {
  .headerwrapper .header-strip .tsupport p{
    display: none;
  }

}
@media only screen and (max-width: 530px) {
  .headerwrapper .header-strip .social-icons li:nth-child(3){
        display: none;
  }
  .headerwrapper .header-strip .social-icons li:nth-child(2){
        display: none;
  }
}
@media only screen and (max-width: 440px){
  .headerwrapper .header-strip{
    height: 13px;
    padding: 10px !important;
  }
  .headerwrapper .header-strip p{
    font-size: 10px;
  }
  .headerwrapper .header-strip .social-icons svg{
    font-size: 15px;
    position: relative;
    left: 19px;

  }
  .headerwrapper .header-strip .num svg{
    font-size: 17px !important;
    position: relative;
    left: 4px;
    bottom: 1px;
  }
  .headerwrapper .header-strip .num p{
    position: relative;
    top: 4px;
  }
  .headerwrapper .header-strip .email svg{
    font-size: 14px;
    position: relative;
    left: 4px;
    bottom: 1px;
  }


}




.homebannerSection img{height: 600px;} 
.homebannerSection{position: relative;}
.homebannerSection .swiper{overflow: hidden;}
.homebannerSection *{outline: none !important;}
/* Make the swiper container responsive */
.swiper-container {
  width: 100%;
  height: auto; 
}


.swiper-slide img {
  width: 100%;
  height: auto;
  object-fit: cover; 
}

@media  (max-width:451px) {
  .swiper-slide img {
    width: 100%;
    height: 400px;
    object-fit: cover; 
  }
}

.homebannerSection .swiper .swiper-button-next{position: absolute;top: 50%;right: 1%;width: 40px;height: 40px;background-color: #009e0f;color: #000; border-radius: 50%;}
.homebannerSection .swiper .swiper-button-prev{position: absolute;top: 50%;left: 1%;width: 40px;height: 40px;background-color: #009e0f;color: #000; border-radius: 50%;}
.homebannerSection .swiper .swiper-button-next::after{
  font-size: 15px;
  color: #ffffff;
}
.homebannerSection .swiper .swiper-button-prev::after{
  font-size: 15px;
  color: #ffffff;
}

.swiper-pagination-bullet-active{
  background-color: #009e0f;
}

@media (max-width: 768px) {
  .homebannerSection .swiper .swiper-button-next,
  .homebannerSection .swiper .swiper-button-prev {
      width: 30px;
      height: 30px;
      top: 52%;
  }
  .homebannerSection .swiper .swiper-button-next::after,
  .homebannerSection .swiper .swiper-button-prev::after {
      font-size: 13px;
  }
}

/* Media Query for mobile screens */
@media (max-width: 560px) {
  .homebannerSection .swiper .swiper-button-next,
  .homebannerSection .swiper .swiper-button-prev {
      width: 20px;
      height: 20px;
      top: 54%;

  }
  .homebannerSection .swiper .swiper-button-next::after,
  .homebannerSection .swiper .swiper-button-prev::after {
      font-size: 11px;
  }
  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active{
   width: 4px;
   height: 4px;
  }

}


 .domainpagetldswrapper .tldswrapper {
  width: 380%;
 
  background: white;
  border-radius: 16px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: all 0.3s ease;
}
 .hometldswrapper{
  padding: 10px;
  background-color: #f7f7f7;
  margin-bottom: 15px;
 } 
 .hometldswrapper .tldswrapper {
  width: 100%;
 
  background: white;
  border-radius: 16px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: all 0.3s ease;
}

.tldswrapper:focus-within {
  transform: translateY(-2px);
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.12);
}
@media (max-width:1590px) {
  .domainpagetldswrapper .tldswrapper{
    width: 280%;
  }
}
@media (max-width:1200px) {
  .domainpagetldswrapper .tldswrapper{
    width: 220%;
  }
}
@media (max-width:955px) {
  .domainpagetldswrapper .tldswrapper{
    width: 150%;
  }
}
@media (max-width:636px) {
  .domainpagetldswrapper .tldswrapper{
    width: 100%;
  }
}
@media (max-width:562px) {
  .domainpagetldswrapper .tldswrapper{
    width: 95%;
  }

}


.domainwrapper {
  display: flex;
  border-radius: 12px;
  overflow: hidden;
}

.domainsearch {
  flex: 1;
}

.domainwrapper .search-input {
  width: 100%;
  padding: 20px 24px;
  font-size: 16px;
  border: 2px solid transparent;
  background: white;
  transition: all 0.3s ease;
}

.domainwrapper .search-input:focus {
  outline: none;
  border-color: var(--theme-color);
}

.domainwrapper .search-input::placeholder {
  color: #94a3b8;
}

.domainwrapper .btndomain {
  display: flex;
  align-items: stretch;
  background-color: var(--theme-color);
}


.domainwrapper .btndomain .search-button {
  padding: 0 32px !important;
  background-color: var(--theme-color ) !important;
  color: white !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  letter-spacing: 0.5px !important;
  white-space: nowrap !important;
  text-transform: none !important;
}

.search-button:hover {
  background-color: #1d4ed8 !important;
}


@media (max-width: 640px) {
  .tldswrapper {
    margin: 10px;
  }



  .search-input {
    padding: 16px;
    font-size: 14px;
  }

  .search-button {
    width: 100% !important;
    padding: 16px !important;
    font-size: 14px !important;
  }
}


.welcome {
  width: 100%;
  text-align: left;
  border-right: 1px solid #06ae16;
  padding-right: 20px;
}

.welcome span:nth-child(1) {
  font-size: 27px;
  font-weight: 600;
  color: #000;
}

.welcome span:nth-child(2) {
  font-size: 32px;
  font-weight: 700;
  color: #009e0f;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
    0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

.hostd span {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
}

.hostd {
  width: 100%;
  height: 85px;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.weldes {
  border-bottom: 1px solid;
  padding: 30px 0px;
}

.exfea .welcome span:nth-child(2) {
  font-size: 52px;
  font-weight: 700;
  color: #009e0f;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
    0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

.hostd img {
  height: 200px;
  margin-right: 70px;
}

.hostd img:nth-child(1) {
  height: 200px;
  margin-left: 70px;
}


@media (max-width: 1004px) {
  .welcome {
    border-right: 0;
  }
}


@media (min-width: 500px) and (max-width: 821px) {
  .hostd span {
    font-size: 11px;
  }
  .hostd {
    padding: 0px 2px 0px 19px;
  }
  .welcome span:nth-child(1) {
    font-size: 22px;
  }
  .welcome span:nth-child(2) {
    font-size: 28px;
  }
}

/* For small screens below 500px */
@media (max-width: 500px) {
  .hostd span {
    font-size: 7.5px;
  }

  .hostd {
    padding: 0px 2px 0px 10px;
  }

  .welcome span:nth-child(1) {
    font-size: 10px;
    padding-left: 18px;
  }

  .welcome span:nth-child(2) {
    font-size: 15px;
    padding-left: 18px;
  }

  .welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-right: none;
  }
}

/* For extra small screens below 480px */
@media (min-width: 290px) and (max-width: 480px) {
  .hostd span {
    font-size: 7px;
  }

  .hostd {
    padding: 0px 2px 0px 10px;
  }

  .welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .welcome span:nth-child(1) {
    font-size: 10px;
    padding-left: 18px;
  }

  .welcome span:nth-child(2) {
    font-size: 15px;
    padding-left: 18px;
  }
}

/* For the very smallest screens */
@media (max-width: 375px) {
  .hostd {
    padding: 0px 10px;
  }

  .hostd span {
    font-size: 6px;
  }

  .welcome span:nth-child(1) {
    font-size: 9px;
  }

  .welcome span:nth-child(2) {
    font-size: 14px;
  }
}


/* HostingPlans.css */


.pricing-plans-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 40px;
  background-color: #f9f9f9;
}

.features-column {
  flex: 1;
  max-width: 200px;
}

.pricing-plans-container .feature-item {
  background-color: #4cb456;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 8px;
  font-weight: bold;
}

.plans-column {
  display: flex;
  flex: 3;
  gap: 20px;
  flex-wrap: wrap;
}

.plan-card {
  flex: 1;
  min-width: 270px;
  background-color: white;
  border: 2px solid #4cb456;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.plan-title {
  font-size: 1.75rem;
  color: #4cb456;
  font-weight: bold;
  margin-bottom: 10px;
}

.plan-details {
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

.plan-detail {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px;
}

.plan-price {
  font-size: 1.5rem;
  color: #4cb456;
  font-weight: bold;
  margin-bottom: 20px;
}

.view-plans-button {
  background-color: #4cb456;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-plans-button:hover {
  background-color: #3e993d;
}

@media (max-width: 768px) {
  .pricing-plans-container {
    flex-direction: column;
    align-items: center;
  }

  .plans-column {
    flex-direction: column;
    align-items: center;
  }
}




.pricing-plans-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 40px;
  background-color: #f9f9f9;
}

.features-column {
  flex: 1;
  max-width: 200px;
}

.pricing-plans-container .feature-item {
  background-color: #4cb456;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 8px;
  font-weight: bold;
}

.plans-column {
  display: flex;
  flex: 3;
  gap: 20px;
  flex-wrap: wrap;
}

.plan-card {
  flex: 1;
  min-width: 250px;
  background-color: white;
  border: 2px solid #4cb456;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.plan-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.plan-details {
  margin-bottom: 20px;
}

.plan-detail {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
}

.plan-price {
  font-size: 1.25rem;
  color: #4cb456;
  font-weight: bold;
  margin-bottom: 20px;
}

.view-plans-button {
  background-color: #4cb456;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-plans-button:hover {
  background-color: #3e993d;
}

@media (max-width: 768px) {
  .pricing-plans-container {
    flex-direction: column;
    align-items: center;
  }

  .plans-column {
    flex-direction: column;
    align-items: center;
  }
}












.features-container {
  background-color: #4cb456;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: white;
}

.pricing-plans-container .feature-item {
  text-align: center;
  margin: 16px;
}

.pricing-plans-container .feature-item img {
  margin: 12px auto;
}

.domains-container {
  background-color: white;
  padding: 24px;
  text-align: center;
}

.domains-container h2 {
  color: #4cb456;
  font-size: 24px;
  font-weight: bold;
}

.domains-container p {
  color: gray;
  margin-bottom: 16px;
}

.domains-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); 
  gap: 16px; 
  justify-content: center; 
  margin: 0 auto; 
}

.domain-item {
  outline: 1px solid #00c800;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-in-out;
  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      line-height: 59px;
      height: 250px;
    
}
.domain-item h3::before{
  content: '.';
  color: #00c800;
}

.domain-item:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.domain-item h3 {
  color: #000;
  font-size: 40px;
  font-weight: bold;
}

.domain-item p {
  color: gray;
  margin-bottom: 12px;
}
.domain-item p  span {
  color:#00c800;
  margin-bottom: 13px;
}

.domain-item button {
  background-color: #4cb456;
  color: white;
  padding: 8px 51px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  line-height: 33px;

}

.domain-item button:hover {
  background-color: #3a9c45;
}





/* .hostingcatplan .hosting-plans {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
}

.hostingcatplan .plan {
  background-color: #00c800;
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
  transition: transform 0.3s ease;
}

.hostingcatplan .plan:hover {
  transform: scale(1.05);
}

.hostingcatplan .plan h4 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.hostingcatplan .plan h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.hostingcatplan .plan h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.hostingcatplan.plan p {
  font-size: 0.9rem;
  line-height: 1.5;
}



@media (min-width: 1024px) and (max-width: 1366px) {
  .hostingcatplan .plan {
    width: 30%; 
  }
}


@media (min-width: 768px) and (max-width: 1023px) {
  .hostingcatplan .plan {
    width: 45%; 
  }
}

@media (max-width: 767px) {
  .hostingcatplan .plan {
    width: 100%;
  }
} */

.exfea{

  display: flex;
  flex-direction: column;

}
.exfea .welcome{
  width: 100%;
   text-align: center;
   display: flex;
}
.exfea .exfeaimg{
  width: 100%;
   text-align: center;
   display: flex;
}
.exfea .exfeaimg img{
  width: 100%;
  justify-content: center;
  align-items: center;
}



















/* -------Domain Registration Page------ */

.domain-banner{
  height: 450px;
  width: 100%;

 background-image: url("./assets/domainbanner.webp");
 display: flex;
 align-items: center;
 background-repeat: no-repeat;
 background-position: center;
 background-size: cover; /* Ensures the background image covers the container */
 
 justify-content: center;
 padding: 10px 70px;
} 
.domain-banner .domain-ban-left{
  text-align: center;
  flex: 0 0 100%;
 
  height: 450px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  row-gap: 12px;
  flex-direction: column;
}
@media (max-width:562px) {
  .tldswrapper{
    width: 100%;
  }
  .domain-banner{
    padding: 20px;
  }
}


.domain-ban-left h6{
  font-size: 24px;
  color: #000;
  font-weight: 700;
}
.domain-ban-left h1{
  font-size: 43px;
  color: #ffffffde;
}
.domain-ban-left span p{
  color: #000;
  font-weight: 600;
  font-size: 18px;
}
.domain-ban-left span {
  font-weight: 550;
 font-size: 17px;
}

@media (max-width:650px) {
  .domain-ban-left span p{

    font-size: 16px;
  }
  .domain-ban-left span {
  
   font-size: 15px;
  }
  .domain-ban-left h6{
    font-size: 18px;
 
  }
  .domain-ban-left h1{
    font-size: 34px;

  }

}
@media (max-width:526px) {
  .domain-ban-left span p{

    font-size: 11px;
  }
  .domain-ban-left span {
  
   font-size: 10px;
  }
  .domain-ban-left h6{
    font-size: 16px;
 
  }
    

  .domain-ban-left h1{
    font-size: 20px;

  }
 
}


 




/* ----------------Questionmaries start ------------------- */
.qa-container {
  max-width: 1200px; /* Further increased container width */
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.qa-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}
.qa-item svg{
  color: #008c0d;
}

.qa-item:last-child {
  border-bottom: none;
}

/* Question Header */
.qa-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.3s ease; /* Smooth transition for color */
}

.qa-header.active {
  color: #008c0d; /* Light blue color when active */
}

.question {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #000; /* Default black color */
  transition: color 0.3s ease; /* Smooth color transition */
}

/* Hover effect for question */
.question:hover {
  color: #008c0d; /* Light blue color on hover */
}

.icon {
  font-size: 1.2rem;
  color: #333;
  transition: transform 0.3s ease;
}

.answer {
  font-size: 1rem;
  color: #555;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  max-height: 0;
  padding: 0;
}

/* Hover effect for answer */
.answer:hover {
  background-color: #f0f8ff; /* Light blue background on hover */
}

.answer.open {
  max-height: 500px; /* Adjust to your content size */
  padding: 10px 0;
}

/* Responsive Design */
@media (max-width: 600px) {
  .qa-container {
    padding: 10px;
  }
  .question {
    font-size: 1rem;
  }
  .answer {
    font-size: 0.9rem;
  }
}

/* ----------------Questionmaries end ------------------- */


/* ----------------Domaintips start ------------------- */
.tips-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tips-container h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #008c0d;
}
.tips-container p {
  text-align: center;
 padding: 10px;
  color: #333;
}

.tips-container .tips-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tips-container .tip-item {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.tips-container .tip-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: #008c0d;
  margin-right: 15px;
}

.tips-container .tip-content {
  display: flex;
  flex: 1;
}

.tips-container .tip-icon {
  font-size: 2rem;
  color: #333;
  margin-right: 15px;
}

.tips-container .tip-details h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.tips-container .tip-details p {
  margin: 5px 0 0;
  font-size: 1rem;
  color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
  .tips-container .tips-list {
    gap: 15px;
  }

  .tips-container .tip-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .tips-container .tip-number {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .tips-container .tip-icon {
    font-size: 1.5rem;
  }

  .tips-container .tip-details h3 {
    font-size: 1rem;
  }

  .tips-container .tip-details p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .tips-container .tips-container {
    padding: 10px;
  }

  .tips-container  h2 {
    font-size: 1.5rem;
  }

  .tips-container .tip-number {
    font-size: 1rem;
 
  }

  .tips-container .tip-icon {
    font-size: 1.3rem;
  }

  .tips-container .tip-details h3 {
    font-size: 1rem;
  }

  .tips-container .tip-details p {
    font-size: 0.9rem;
  }
}

.domain-promo {
  background-color: #f4f4f4;
  text-align: center;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.domain-promo h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 15px;
}

.domain-promo .price {
  font-size: 1.5rem;
  color: #333;
}

.domain-promo .price span {
  color: #008c0d;
  font-weight: bold;
  font-size: 1.8rem;
}

.domain-promo .get-started-btn {
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 1.1rem;
  background-color: #008c0d; /* Green button */
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.domain-promo .get-started-btn:hover {
  background-color: #009900; 
  transform: scale(1.05); 
}

@media (max-width: 600px) {
  .domain-promo {
    padding: 30px;
  }

  .domain-promo h2 {
    font-size: 1.8rem;
  }

  .domain-promo .price {
    font-size: 1.2rem;
  }

  .domain-promo .get-started-btn {
    padding: 12px 25px;
    font-size: 1rem;
  }
}



/* ----------------Domaintips  end ------------------- */


/* ----------------PK Domain  Start ------------------- */

.edu-domain-requirements {
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  font-size: 2rem;
  color: #333;
}

.intro, .inactive-notice, .address {
  font-size: 1.1rem;
  color: #444;
  line-height: 1.6;
  margin-bottom: 15px;
}

.inactive-notice {
  font-weight: bold;
  color: #ff0000; /* Highlight with red color */
}

h3 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
}

.requirements-list {
  list-style-type: decimal;
  padding-left: 20px;
  font-size: 1.1rem;
  color: #555;
}

.requirements-list li {
  margin-bottom: 10px;
}

.address {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
}

.address-details {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-top: 10px;
}

address {
  font-style: normal;
}

/* Responsive Design */
@media (max-width: 768px) {
  .edu-domain-requirements {
    padding: 15px;
  }

  h2 {
    font-size: 1.8rem;
  }

  .intro, .inactive-notice, .address {
    font-size: 1rem;
  }

  .requirements-list {
    font-size: 1rem;
  }

  .address-details {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .edu-domain-requirements {
    padding: 10px;
  }

  h2 {
    font-size: 1.5rem;
  }

  .intro, .inactive-notice, .address {
    font-size: 0.9rem;
  }

  .requirements-list {
    font-size: 0.9rem;
  }

  .address-details {
    font-size: 0.9rem;
  }
}

/* ---------------- PK Domain  end ------------------- */


/* ---------------- PK Domain Renew Start ------------------- */
.domain-details {
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  font-size: 2rem;
  color: #333;
}

.domain-details p {
  font-size: 1.1rem;
  color: #444;
  line-height: 1.6;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
}

ul {
  list-style-type: decimal;
  padding-left: 20px;
  font-size: 1.1rem;
  color: #555;
}

ul li {
  margin-bottom: 10px;
}

address {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

.renew-btn {
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 1.1rem;
  background-color: #00c800; /* Green button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.renew-btn:hover {
  background-color: #009900; /* Darker green on hover */
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.text-content {
  flex: 1;
  max-width: 60%;
}

.image-content {
  flex: 1;
  max-width: 35%;
  display: flex;
  justify-content: flex-start;
}

.renew-image {
  width: 340px; /* Smaller width */
  height: auto;
  margin-left: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .domain-details {
    padding: 15px;
  }

  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }

  .renew-btn {
    padding: 12px 25px;
    font-size: 1rem;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .text-content {
    max-width: 100%;
    text-align: center;
  }

  .image-content {
    max-width: 100%;
    margin-top: 20px;
    text-align: center;
  }

  .renew-image {
    width: 150px; /* Even smaller width on tablets */
  }
}

@media (max-width: 480px) {
  .domain-details {
    padding: 10px;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  .renew-btn {
    padding: 12px 20px;
    font-size: 0.9rem;
  }

  .renew-image {
    width: 120px; /* Smaller width on mobile */
  }
}

/* ---------------- PK Domain Renew   end ------------------- */








/* ---------------- Shared Hostiog  Start ------------------- */




.shared-head-banner{
  background-image: url("./assets/shared\ head\ banner-min.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  border-top: 1px solid black;
 
  justify-content: center;
  align-items: center;
}
.wordpress-banner .shared-head-banner{
  background-image: url("./assets/wordpressbanner-min.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  border-top: 1px solid black;
 
  justify-content: center;
  align-items: center;
}

.business-hosting-banner .shared-head-banner{
  background-image: url("./assets/businesshostingbanner-min.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  border-top: 1px solid black;
 
  justify-content: center;
  align-items: center;
}
.resllerhostinghead .shared-head-banner{
  background-image: url(https://www.ehostpk.com/wp-content/uploads/2022/06/RESELLER.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  border-top: 1px solid black;
 
  justify-content: center;
  align-items: center;
}

.shared-head-banner span{
  display: flex;
  color: #fff;
  font-size: 45px;
  height: 70px;
  width: auto;
  align-items: center;
  text-transform: uppercase;
  font-weight: 650;
  text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.8), 2px 2px 5px rgba(0, 0, 0, 0.5);

}
.shared-head-banner span svg{
  color: #fff;
  font-size: 70px;
  font-weight: 650;
  text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.8), 2px 2px 5px rgba(0, 0, 0, 0.5);


}
.shared-head-banner span p{
  padding-left: 7px;

}
/* ---------------- Shared Hostiog  end ------------------- */



/* ---------------- FEature box start------------------- */

/* Container Styling */
.shared-hosting-container {
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  border-bottom: 1px solid grey;

}

/* Main Heading */
.shared-hosting-heading {
  text-align: center;
  font-size: 2.5rem;
  color: #000;
  margin-bottom: 30px;
}

/* Grid Layout */
.shared-hosting-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Hosting Feature Box */
.hosting-feature-box {
  width: 500px; 
  height: 400px;
  background-color: #f7f7f7;
  border: 2px solid #008c0d;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Hover Effect */
.hosting-feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

/* Icon Circle Styling */
.icon-circle {
  width: 70px;
  height: 70px;
  border: 3px solid #008c0d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.icon-circle svg {
  font-size: 2rem;
  color: black; /* Changed icon color to black */
}

/* Feature Title */
.feature-title {
  font-size: 1.5rem;
  color: #008c0d;
  margin-bottom: 10px;
}

/* Feature Description */
.feature-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Feature Button */
.feature-button {
  background-color: #008c0d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feature-button:hover {
  background-color: #009900;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hosting-feature-box {
    width: 100%;
  }


  .shared-hosting-grid {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .hosting-feature-box {
    width: 100%;
    height: 500px;
  }
}



/* ---------------- FEature box  end ------------------- */






/* ---------------- Demo box  start ------------------- */

.demo-box { 
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  transition: all 0.3s ease-in-out;
}

.demo-box h2 {
  color: #000;
  border-bottom: 1px solid #008c0d;
  text-transform: capitalize;
  margin-bottom: 25px;
  padding-bottom: 15px;
  font-weight: bold;
  text-align: center;
}

.columns-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap; /* Allows stacking on smaller screens */
}

.columns-container .column {
  width: 48%;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: auto;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out;
}

.columns-container .column:hover {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.columns-container .column h3 {
  font-size: 18px;
  margin-top: 15px;
  color: #333;
}

.column button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 15px;
  transition: background-color 0.3s;
}

.column button:hover {
  background-color: #45a049;
}

.image-container {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
}

.demo-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.demo-image:hover {
  transform: scale(1.1);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .columns-container {
    flex-direction: column;
    align-items: center;
  }

  .columns-container .column {
    width: 90%;
    height: auto;
    padding: 15px;
  }

  .image-container {
    height: 200px;
  }

  .columns-container .column h3 {
    font-size: 16px;
  }

  .column button {
    font-size: 14px;
    padding: 8px 15px;
  }
}

@media (max-width: 768px) {
  .demo-box {
    padding: 15px;
  }

  .columns-container .column {
    width: 100%;
  }

  .image-container {
    height: 180px;
  }

  .columns-container .column h3 {
    font-size: 14px;
  }

  .column button {
    font-size: 14px;
    padding: 8px 12px;
  }
}

/* ---------------- Demo box  end ------------------- */




.description-box {
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 8px;
  max-width: 1500px;
  margin: 30px auto;
  font-family: Arial, sans-serif;
  color: #333;
  opacity: 0; /* Ensure initial state is transparent */
  transition: opacity 1s ease-in-out; /* Smooth opacity transition */
}

.description-box h2,
.description-box h3 {
  color: #008c0d;
  margin: 55px 15px;
}

.description-box p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.description-box p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .description-box {
    padding: 20px;
    margin: 20px;
  }
}


.support-section2 {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  max-width: 1200px;
  margin: 20px auto;
  color: #333;
  display: flex
  ;
      flex-direction: column;
      row-gap: 20px;
      outline: 1px solid rgba(0, 0, 0, 0.5);
  
}

.support-section2 h2 {
  color: #008c0d;
  font-size: 2em;
}

.support-section2 h3 {
  color: #008c0d;
  font-size: 1.5em;
}

.support-section2 p {
  font-size: 1.1em;
  line-height: 1.6;
}

.support-section2 .link {
  color: #005e09;
  text-decoration: none;
}

.support-section2 .link:hover {
  text-decoration: underline;
}

.location-point{
  width: 100%;
  height: 300px;
  border: 1px solid grey;
  margin-bottom: 20px;
}
.location-point iframe{
  width: 100%;
  height: 300px;
}


/* SocialIcons.css */

.large-social-icons .social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.large-social-icons{
  position: relative;
  left: 350px;
}
@media (max-width:980px) {
  .large-social-icons{
  
    left: 180px;
  }
}
@media (max-width:720px) {
  .large-social-icons{
  
    left: 130px;
  }
}
@media (max-width:500px) {
  .large-social-icons{
  
    left: 70px;
  }
}
@media (max-width:400px) {
  .large-social-icons{
  
    left: 20px;
  }
}
.large-social-icons .icon {
  font-size: 50px; /* Size of the icon */
  color:rgba(0, 0, 0, 0.486); /* Default color */
  transition: transform 0.3s ease, color 0.3s ease;
}

.whatsapp:hover {
  color: #25d366; /* WhatsApp green */
}

.facebook:hover {
  color: #3b5998; /* Facebook blue */
}

.twitter:hover {
  color: #1da1f2; /* Twitter blue */
}

.instagram:hover {
  color: #e1306c; /* Instagram pink */

}



.about-head-banner{
  background-image: url("./assets/about-usbanner-min.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  border-top: 1px solid black;
 
  justify-content: center;
  align-items: center;
}
.about-head-banner span{
  display: flex;
  color: #fff;
  font-size: 45px;
  height: 70px;
  width: auto;
  align-items: center;
  text-transform: uppercase;
  font-weight: 650;
  text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.8), 2px 2px 5px rgba(0, 0, 0, 0.5);

}
.about-head-banner span svg{
  color: #fff;
  font-size: 70px;
  font-weight: 650;
  text-shadow: 0 0 5px white, 0 0 10px rgba(255, 255, 255, 0.8), 2px 2px 5px rgba(0, 0, 0, 0.5);


}
.about-head-banner span p{
  padding-left: 7px;

}


/* GreatAt.css */

.great-at-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  gap: 40px;
}

.text-section {
  flex: 1; /* Take up available space */
}

.text-section h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.text-section .content {
  margin-bottom: 30px;
}

.text-section h3 {
  font-size: 24px;
  color: #008c0d; /* App color */
}

.text-section p {
  font-size: 16px;
  color: #555;
}

.image-section img {
  max-width: 100%;
  height: auto;
  width: 600px; /* Control the image size */
  object-fit: cover; /* Make sure the image looks good */
}

/* Make it responsive */
@media (max-width: 768px) {
  .great-at-container {
    flex-direction: column; /* Stack text and image on smaller screens */
    align-items: center;
  }

  .image-section img {
    width: 100%; /* Make image fill the container */
    max-width: 500px;
  }
}




/* CustomerReviews.css */
.reviews-container-wrapper{
  width: 100%;
  height: 20%;
  background-color: #f7f7f7;
  padding: 40px 30px;
}
.reviews-container {
  width: 100%;
  max-width: 1030px; 
  margin: 2rem auto;
}
.reviews-container h3 {
text-align: center;
font-size: 20px;
color: #006b07;
}

.reviews-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
}

.review-slide {
  padding: 1rem;
}

.review-card {
  background-color: transparent;
  padding: 1.5rem;
  border-radius: 1rem;

  text-align: center;
  display: flex
  ;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 30px;
  
  height: 300px; /* Fixed height */
  border-radius: 70px;

}

.review-text {
  font-size: 1.1rem;
  color: #555;
}

.review-author {
  margin-top: 1rem;
  font-weight: bold;
  color: #333;
}

.custom-nav {
  background-color: #008c0d; /* Background color */
  color: white; /* Button color */
  width: 40px; /* Reduced size */
  height: 40px; /* Reduced size */
  border-radius: 50%; /* Circular buttons */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.reviews-container .swiper .swiper-button-next{position: absolute;top: 50%;right: 4%;width: 40px;height: 40px;background-color: #009e0f;color: #000; border-radius: 50%;}
.reviews-container .swiper .swiper-button-prev{position: absolute;top: 50%;left: 4%;width: 40px;height: 40px;background-color: #009e0f;color: #000; border-radius: 50%;}
.reviews-container .swiper .swiper-button-next::after{
  font-size: 15px;
  color: #ffffff;
}
.reviews-container .swiper .swiper-button-prev::after{
  font-size: 15px;
  color: #ffffff;
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal{
  position: relative
  ;
  bottom: 50px;
}




.wordpress-des{
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 1400px;
  width: 100%;
  background-color: #c7c7c7;
  padding: 10px 30px;
}
.wordpress-des .wordpress-des1{
  flex: 0 0 30%;
  align-items: center;
  justify-content: center;

  padding: 30px 40px;
  display: flex;
}
.wordpress-des .wordpress-des1 .wordpress-des1-1{
  flex: 0 0 50%;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 20px 30px;
  row-gap: 36px;
 
}
.wordpress-des .wordpress-des1 .wordpress-des1-1-img{
  flex: 0 0 50%;
  text-align: center;
  height: 300px;
}
.wordpress-des .wordpress-des1 .wordpress-des1-1-img img{

  height: 400px;
    width: 501px;
}
.wordpress-des .wordpress-des1 .wordpress-des1-1 h2{
  color: #008c0d;
  padding-bottom: 5px;
  border-bottom:1px solid #008c0d;
  font-size: 40px;
  

}
.wordpress-des .wordpress-des1 .wordpress-des1-1 button{
  color: #fff;
  padding-top: 5px;
  width: 210px;
  height: 60px;
  font-size: 20px;
  border-radius: 8px;
  background: #008c0d;
  

}
.wordpress-des .wordpress-des1 .wordpress-des1-1 p{
  text-align: left;
  font-size: 18px;
  

}


.wordpress-des .wordpress-des2{
  flex: 0 0 30%;
}

.wordpress-des .wordpress-des2{
  flex: 0 0 30%;
  align-items: center;
  justify-content: center;

  padding: 30px 40px;
  display: flex;
}
.wordpress-des .wordpress-des2 .wordpress-des2-2{
  flex: 0 0 50%;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 20px 30px;
  row-gap: 36px;
 
}
.wordpress-des .wordpress-des2 .wordpress-des2-2-img{
  flex: 0 0 50%;
  text-align: center;
  

}

.wordpress-des .wordpress-des2 .wordpress-des2-2-img img{

  
  height: 400px;
  width: 501px;
}
.wordpress-des .wordpress-des2 .wordpress-des2-2 h2{
  color: #008c0d;
  padding-bottom: 5px;
  border-bottom:1px solid #008c0d;
  font-size: 40px;
  

}
.wordpress-des .wordpress-des2 .wordpress-des2-2 button{
  color: #fff;
  padding-top: 5px;
  width: 210px;
  height: 60px;
  font-size: 20px;
  border-radius: 8px;

  

}
.wordpress-des .wordpress-des2 .wordpress-des2-2 p{
  text-align: left;
  font-size: 18px;
  

}





.wordpress-des .wordpress-des3{
  flex: 0 0 30%;
}

.wordpress-des .wordpress-des3{
  flex: 0 0 30%;
  align-items: center;
  display: flex;
  justify-content: center;

  padding: 5px 40px;
}
.wordpress-des .wordpress-des3 .wordpress-des3-3{
  flex: 0 0 50%;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 20px 30px;
  row-gap: 36px;
 
}
.wordpress-des .wordpress-des3 .wordpress-des3-3-img{
  flex: 0 0 50%;
  text-align: center;

}
.wordpress-des .wordpress-des3 .wordpress-des3-3-img img{

  height: 400px;
  width: 501px;
}
.wordpress-des .wordpress-des3 .wordpress-des3-3 h2{
  color: #008c0d;
  padding-bottom: 5px;
  border-bottom:1px solid #008c0d;
  font-size: 40px;
  

}
.wordpress-des .wordpress-des3 .wordpress-des3-3 button{
  color: #fff;
  padding-top: 5px;
  width: 210px;
  height: 60px;
  font-size: 20px;
  border-radius: 8px;

  

}
.wordpress-des .wordpress-des3 .wordpress-des3-3 p{
  text-align: left;
  font-size: 18px;
  

}
@media  (max-width:1048px){
  .wordpress-des{
    display:none;
  }
}




.topfeature-box .features-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 20px;
  padding: 40px;
  text-align: center;
  background-color: white;
  border-bottom: 1px dotted #008c0d;

  
}
.topfeature-box{  background-color: white;}
.topfeature-box h1{ color: #008c0d;
padding: 12px 0;
text-align: center;}
.topfeature-box h4{ color: grey; text-align: center;}

.topfeature-box .feature-box {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 400px;
  width: 100%; 
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.topfeature-box .feature-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: #008c0d;
  color: white;
  font-size: 24px;
  margin: 0 auto 15px auto;
}
.topfeature-box .feature-icon svg{
  font-size: 40px;
}
.topfeature-box .feature-title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #008c0d;
}

.topfeature-box .feature-text {
  font-size: 14px;
  color: #555;
}

/* Responsive styles */
@media (max-width: 768px) {
  .topfeature-box .features-container {
    grid-template-columns: 1fr; 
    padding: 20px;
  }

  .topfeature-box .feature-box {
    height: auto; 
    width: 100%; 
  }

  .topfeature-box .feature-title {
    font-size: 30px; 
  }

  .topfeature-box .feature-text {
    font-size: 12px; 
  }
}


.plancontainerbig {
  display: grid;
  grid-template-columns: repeat(4, 350px); /* 4 items per row with fixed width of 350px each */
  gap: 20px; /* Space between items */
  padding: 20px;
  justify-content: center; /* Centers the grid horizontally */
}

.plans-container {
  display: grid;
  grid-template-columns: repeat(4, 350px); /* 4 items per row with fixed width */
  gap: 20px; /* Space between items */
  justify-content: center; /* Centers the grid horizontally */
  width: 100%;
}

/* Plan container styling */
.plancontainer {
  width: 350px; /* Fixed width of each container */
  height: auto; /* Adjust height based on content */
  border: 2px solid #005f07;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plancontainer:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.plancontainer .planprice {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.plancontainer .planprice span {
  text-align: center;
  text-transform: capitalize;
  font-size: 20px;
}

.plancontainer .planprice p {
  text-align: center;
  font-size: 70px;
  color: #7A7A7A;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.plancontainer .plantitle {
  width: 100%;
  height: 135px;
  background-color: #006b07;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  font-size: 50px;
  border-radius: 10px;
  margin-bottom: 35px;
  color: white;
}

.plancontainer .plandescription {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}

.plancontainer button {
  background-color: #005f07;
  width: 250px;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 10px;
  color: white;
  transition: background-color 0.3s ease;
}

.plancontainer button:hover {
  background-color: #004a06;
}

/* Responsive grid for medium screens (2 items per row) */
@media (max-width: 1024px) {
  .plans-container {
    grid-template-columns: repeat(2, 350px); /* 2 items per row on medium screens with fixed width */
  }

  .plancontainer {
    width: 350px; /* Maintain 350px width */
    height: auto;
  }
}

/* Responsive grid for small screens (1 item per row) */
@media (max-width: 768px) {
  .plans-container {
    grid-template-columns: 1fr; /* 1 item per row on mobile screens */
  }

  .plancontainer {
    width: 350px; /* Maintain 350px width */
    height: auto;
    margin: 10px;
  }

  .plancontainer .plantitle {
    font-size: 30px;
  }

  .plancontainer .planprice p {
    font-size: 40px;
  }

  .plancontainer .plandescription span {
    width: 100%;
  }

  .plancontainer button {
    width: 100%;
  }
}



/* General grid container */

.planofferhome{
  background-color: #f7f7f7;

  padding: 20px 0px;
}
.planofferhome h1{
text-align: center;
color: #008c0d;
}
.planofferhome .packagewrapperhosting {
  background:#f7f7f7;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 1px solid #ddd;
  position: relative;
  width: 350px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
}
.planofferhome .packagewrapperhosting:hover{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.packagewrapperhosting .badge{
  color: #ffffff;
  background: #008c0d;
  position: absolute;
  top: 0;
  right: 0;
}
.hosting-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 20px;
  justify-items: center; 
  padding: 20px;
  max-width: 1450px; 
  margin: 30px auto; 
}
.planofferhome .hosting-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  justify-items: center;
  padding: 20px;
  max-width: 1450px; 
  margin: 30px auto; 
}

.packagewrapperhosting {
  background:#f7f7f7;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 1px solid #ddd;
  position: relative;
  width: 500px;
  height: 472px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
}
.packagewrapperhosting:hover{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.packagewrapperhosting a{
  width: 170px;
  height: 40px;
  padding: 5px 10px;
  font-size: 16px;
  background-color: #008c0d;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}


.packagewrapperhosting .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto 20px;
}
.packagewrapperhosting .description{
font-size: 15px;

}


.packagewrapperhosting .icon-container svg {
  font-size: 4.5rem;
  color: #008c0d;
}

@media (max-width: 1064px) {
  .hosting-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
  }
}
@media (max-width: 544px) {
  .otherhostinggrid .packagewrapperhosting {
    width: auto;
    max-width: 350px;
  }
}


@media (min-width: 544px) and (max-width: 1084px) {
  .hosting-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 544px) and (max-width: 1084px) {
  .hosting-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media (max-width: 1285px) {
  .planofferhome .hosting-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1285px) {
  .planofferhome .hosting-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 950px) and (max-width:1285px) {
  .planofferhome .packagewrapperhosting {

  
    width: 450px;
 
  }
}
@media (max-width:639px) {
  .planofferhome .packagewrapperhosting {

  
    width: 320px;
    height: 550;
 
  }
  .planofferhome .hosting-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 640px) and (max-width: 750px) {
  .planofferhome .hosting-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .planofferhome .packagewrapperhosting {

  
    width: 500px;
 
  }
}




.businesshostingplans .plans-container{
  display: grid;
  grid-template-columns: repeat(2, 550px); /* 4 items per row with fixed width */
  gap: 20px; /* Space between items */
  justify-content: center; /* Centers the grid horizontally */
  width: 100%;
}
.businesshostingplans{
  background-color: #f7f7f7;
  margin: 30px 10px;
  padding: 30px 10px;
}
.businesshostingplans h2{
  font-size: 40px;
  color: #005f07;
  padding-bottom: 10px;

  text-align: center;


}

.businesshostingplans p{
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}

.resellerhostingplans .plans-container{
  display: grid;
  grid-template-columns: repeat(3, 550px); /* 4 items per row with fixed width */
  gap: 20px; /* Space between items */
  justify-content: center; /* Centers the grid horizontally */
  width: 100%;
}
.resellerbusinesshostingplans{
  background-color: #f7f7f7;
  margin: 30px 10px;
  padding: 30px 10px;
}
.resellerhostingplans h2{
  font-size: 40px;
  color: #005f07;
  padding-bottom: 10px;

  text-align: center;


}

.resellerhostingplans p{
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}







.location-wrapper {
  padding: 20px;
  background-color: white;
  color: white;
  border-radius: 10px;
  margin: 20px 10px;
  

}

.location-wrapper .title {
  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 15px;
  color: #008c0d;
  text-align: center;
  flex-direction: column;
}
.location-wrapper .title p{
  text-align: center;
  font-size: 15px;
  color: grey;
}
.regions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

 .region-title {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #008c0d;

}

 .location-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.location-item {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
}

.flag {
  width: 26px;
  height: 1px;
}


.product-finderwrapper {
  height: auto;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  border-top: 1.5px dotted #008c0d;
  border-bottom: 1.5px dotted #008c0d;
  padding-top: 40px;
}

.product-finderwrapper p {
  text-align: center;
  font-size: 18px;
  width: 55%;
  border-bottom: 1px solid grey;
  padding-bottom: 10px;
}

.product-finderwrapper h2 {
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
  width: 50%;
  color: #008c0d;
}

.productchartbox {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Initially, 4 items per row */
  column-gap: 35px;
  row-gap: 30px;
  margin: 30px 10px;
  padding: 30px 10px;
  border-radius: 8px;
}

.product-finderwrapper .productfinderchart {
  height: 270px;
  width: 100%;
  border: 1.5px solid #008c0d;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 10px 30px;
}
.product-finderwrapper .productfinderchart div {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #008c0d;
  color: #fff;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.product-finderwrapper .productfinderchart:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.product-finderwrapper .productfinderchart svg {
  font-size: 95px;
  position: relative;
  top: -20px;
  color: #7a7a7a;
}

.product-finderwrapper .productfinderchart h3 {
  font-size: 22px;
}

.product-finderwrapper .productfinderchart h3 a {
  font-size: 22px;
  color: #000;
  text-decoration: none;
  cursor: default;
}

/* Responsive Styles using Grid */
@media (max-width: 1024px) {
  .productchartbox {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
  }
}

@media (max-width: 768px) {
  .productchartbox {
    grid-template-columns: 1fr; /* 1 item per row */
  }
  .product-finderwrapper .productfinderchart{
    padding: 10px 80px;
  }
}

@media (max-width: 480px) {
  .product-finderwrapper p {
    width: 95%;
    font-size: 12px;
  }

  .product-finderwrapper h2 {
    width: 95%;
    font-size: 20px;
  }
  .product-finderwrapper .productfinderchart{
    padding: 10px 60px;
  }
  .product-finderwrapper .productfinderchart div{
   width: 104px;
  }
  .product-finderwrapper .productfinderchart svg {
    font-size: 70px;
    top: -10px;
  }

  .product-finderwrapper .productfinderchart h3 {
    font-size: 18px;
  }

  .product-finderwrapper .productfinderchart div {
    width: 100px;
    font-size: 9px;
  }
}




/* Parent Container */
#serversfeatures {
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  background-color: #f4f4f4;
}

/* Grid Layout */
#serversfeatures .features-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

/* Feature Box */
#serversfeatures .feature-box {
  width: 349px;
  height: 422px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-weight: bold;
}

/* Icon Circle */
#serversfeatures .icon-circle {
  width: 80px;
  height: 80px;
  background: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  margin-bottom: 15px;
}

/* Responsive for Tablets */
@media (max-width: 992px) {
  #serversfeatures .features-container {
      grid-template-columns: repeat(2, 1fr);
  }
  #serversfeatures .feature-box:nth-child(3) {
      grid-column: span 2;
  }
}

/* Responsive for Mobile */
@media (max-width: 768px) {
  #serversfeatures .features-container {
      grid-template-columns: 1fr;
  }
}




.containertermsandpolicies .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background: #f8f9fa;
}

/* Title Styling */
.containertermsandpolicies .title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  margin: 20px;
}

/* Section Styling */
.containertermsandpolicies .section {
  background: #c7c7c7;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.containertermsandpolicies .subtitle {
  font-size: 1.8rem;
  font-weight: bold;
  color: #008c0d;
  margin-bottom: 10px;
  padding-bottom:15px;
}

.containertermsandpolicies p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

/* Footer */
.containertermsandpolicies footer {
  text-align: center;
  padding: 15px;
  background: #008c0d;
  color: white;
  margin-top: 30px;
  border-radius: 5px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .containertermsandpolicies .container {
    padding: 15px;
  }
  
  .containertermsandpolicies .title {
    font-size: 2rem;
  }

  .containertermsandpolicies .subtitle {
    font-size: 1.5rem;
  }

  .containertermsandpolicies p {
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .containertermsandpolicies .title {
    font-size: 1.8rem;
  }

  .containertermsandpolicies .subtitle {
    font-size: 1.3rem;
  }

  .containertermsandpolicies .section {
    padding: 15px;
  }

  .containertermsandpolicies p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .containertermsandpolicies .title {
    font-size: 1.5rem;
  }

  .containertermsandpolicies .subtitle {
    font-size: 1.2rem;
  }

  .containertermsandpolicies .section {
    padding: 10px;
  }

  .containertermsandpolicies p {
    font-size: 0.85rem;
  }

  .containertermsandpolicies .footer {
    font-size: 0.9rem;
  }
}
.containertermsandpolicies .footer p{
  color: white;
}



.containersslwrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.containersslwrapper .image-section {
  flex: 1 1 40%;
  min-width: 300px;
  padding: 20px;
}

.containersslwrapper  .content-section {
  flex: 1 1 60%;
  padding: 20px;
}

.containersslwrapper  .ssl-image {
  width: 100%;
  height: auto;
  max-width: 500px;
}

.containersslwrapper  h1 {
  color: #008c0d;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.containersslwrapper  h2 {
  color: #2b3d6d;
  margin: 1.5rem 0;
}

.containersslwrapper  p {
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}

.containersslwrapper  .cta-button {
  display: inline-block;
  background: #2b3d6d;
  color: white;
  padding: 12px 30px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  transition: background 0.3s ease;
}

.containersslwrapper  .cta-button:hover {
  background: #1a2b4d;
}

@media (max-width: 768px) {
  .containersslwrapper   .container {
      flex-direction: column;
      padding: 20px 15px;
  }
  
  .containersslwrapper  h1 {
      font-size: 2rem;
  }
  
  .containersslwrapper  .image-section,
  .containersslwrapper .content-section {
      flex: 1 1 100%;
      width: 100%;
      padding: 10px;
  }
}
 .benefits-container {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center;     /* Center the content vertically (optional if needed) */
  flex-direction: column;
}
 .benefits-container h1{
  text-align: center;
  font-size: 35px;
  color: #008c0d;
  padding-top: 20px;
  padding-bottom: 10px;
}
 .benefits-container p{
  text-align: center;
  /* font-size: 35px; */
  color:grey;
  padding-top: 10px;
  padding-bottom: 20px;
}
 .benefits-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center; /* Center items horizontally within the row */
  width: 100%;  /* Make sure the row takes up full width */
}

.benefit-item {
  flex: 1 1 calc(50% - 1.5rem); /* For 2 items in each row */
  max-width: 50%; /* Ensures 2 items per row */
  box-sizing: border-box;
  margin-bottom: 1.5rem; /* Space between rows */
  display: flex;   /* Ensure the item is a flex container to align the cards inside */
  justify-content: center;  /* Center each card inside the item */
}

.benefit-card {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  border-radius: 8px;
  width: 540px;
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.benefit-icon {
  font-size: 4rem; /* Increased size for icon */
  color: #007bff; /* Customize icon color */
}

.benefit-title {
  font-size: 1.75rem; /* Increased font size for title */
  margin-top: 1rem;
  font-weight: bold;
}

.benefit-description {
  font-size: 1.2rem; /* Increased font size for description */
  margin-top: 0.5rem;
  color: #666;
}

@media (max-width: 767px) {
  .benefit-item {
    flex: 1 1 100%; /* Stacks items in a single column for smaller screens */
    max-width: 100%;
  }
}


.ssl-disclaimer {
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  font-size: 1rem;
  color: #333;
}

.ssl-disclaimer .disclaimer-text {
  line-height: 1.6;
}

.ssl-disclaimer .disclaimer-text strong {
  font-weight: bold;
}

.ssl-disclaimer .disclaimer-point {
  display: block;
  margin-top: 1rem;
  font-style: italic;
  color: #007bff; /* For emphasis */
}

@media (max-width: 767px) {
  .ssl-disclaimer {
    padding: 1rem;
    font-size: 0.9rem;
  }
}


.google-ranking-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 3rem;
  border-top: 1px solid grey;
}

.google-ranking-image img {
  max-width: 300;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 300px; /* You can adjust the image size as needed */
}

.google-ranking-content {
  max-width: 880px;
}

.google-ranking-content h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.google-ranking-content p {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.google-ranking-content blockquote {
  font-size: 1.2rem;
  margin: 1.5rem 0;
  font-style: italic;
  color: #555;
}

.google-ranking-content a {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
}

.google-ranking-content a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .google-ranking-section {
    flex-direction: column;
    align-items: center;
  }

  .google-ranking-image img {
    width: 100%;
    max-width: 400px;
  }

  .google-ranking-content {
    text-align: center;
  }
}




.servercomponentwrapper{
  background-color: #f7f7f7; 
  padding: 50px;
  margin: 30px 40px ;
  border-radius: 15px;
}
.servercomponentwrapper h1{
 text-align: center;
 color: #008c0d;
 padding-top: 15px;
 padding-bottom: 20px;

}
.servercomponentwrapper .components-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.servercomponentwrapper .component-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.servercomponentwrapper .component-card:hover {
  transform: translateY(-5px);
}

.servercomponentwrapper .icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.servercomponentwrapper .icon-blue { color: #1e3a8a; }
.servercomponentwrapper .icon-green { color: #047857; }
.servercomponentwrapper .icon-red { color: #dc2626; }
.servercomponentwrapper .icon-yellow { color: #ca8a04; }
.servercomponentwrapper .icon-indigo { color: #4f46e5; }
.servercomponentwrapper .icon-purple { color: #9333ea; }

.servercomponentwrapper .component-card h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.servercomponentwrapper .component-card p {
  color: #6b7280;
}

.servercomponentwrapper .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f3f4f6;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .servercomponentwrapper .container {
    flex-direction: row;
  }
}






/* Container */
.containerservermanagement {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  gap: 30px;
  background: #f4f4f4;
  margin: 20px;
}
@media (max-width:1118px) {
  .containerservermanagement{
    flex-direction: column;
    row-gap: 50px;
  }
  
}

/* Plan Info */
.containerservermanagement  .plan-info {
  max-width: 720px;
}

.containerservermanagement  .plan-info h2 {
  color: #008c0d;
  font-size: 28px;
}

.containerservermanagement  .highlight {
  font-size: 18px;
  font-weight: bold;
}

.containerservermanagement  .description {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.containerservermanagement .features {
  list-style: none;
  padding: 0;
}

.containerservermanagement  .features li {
  font-size: 16px;
  margin-bottom: 8px;
}

/* Pricing Card */
.containerservermanagement  .pricing-card {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 520px;

}

.containerservermanagement .pricing-card h3 {
  color: #008c0d;
  font-size: 20px;
}

.containerservermanagement  .pricing-features {
  list-style: none;
  padding: 0;
  font-size: 14px;
  margin-bottom: 20px;
}

.containerservermanagement  .price {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.containerservermanagement  .price span {
  font-size: 14px;
  color: #666;
}

.containerservermanagement  .order-btn {
  background-color: #008c0d;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 30px;
}

.containerservermanagement  .order-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* Responsive */
@media (max-width: 768px) {
  .containerservermanagement  .container {
      flex-direction: column;
      text-align: center;
  }
}




.servermanagementdes .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px;
}
.servermanagementdes .text-content {
  flex: 1;
  max-width: 100%;
  padding: 20px;
}
.servermanagementdes .text-content h2{
  color: #008c0d;
  padding-bottom: 20px;
}
.servermanagementdes .image-content {
  flex: 1;
  max-width: 40%;
  text-align: center;
}
.servermanagementdes .image-content img {
  max-width: 80%;
  height: auto;
}
@media (max-width: 768px) {
  .servermanagementdes .container {
      flex-direction: column;
      text-align: center;
  }
  .servermanagementdes .text-content, .image-content {
      max-width: 100%;
  }
  .servermanagementdes .image-content img {
      max-width: 60%;
  }
}