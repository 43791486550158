.whychooseus .features-section {
background: #f8f8f8;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    padding: 80px 0;
  }
  
  .whychooseus .features-section::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(0, 98, 65, 0.03) 0%, transparent 70%);
    pointer-events: none;
  }
  
  .whychooseus .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .whychooseus .section-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 60px;
    background: linear-gradient(135deg, #006241 0%, #00a676 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  
  .whychooseus .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
  }
  
  .whychooseus .feature-card {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(12px);
    border-radius: 24px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px rgba(0, 98, 65, 0.1);
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg);
    transform-style: preserve-3d;
  }
  
  .whychooseus .feature-card:hover {
    transform: perspective(1000px) rotateX(5deg) rotateY(5deg) translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 98, 65, 0.2);
  }
  
  .whychooseus .icon-wrapper {
    width: 64px;
    height: 64px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(20px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .whychooseus .icon-wrapper::before {
    content: "";
    position: absolute;
    inset: -2px;
    border-radius: inherit;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1));
    z-index: 2;
    pointer-events: none;
  }
  
  .whychooseus .icon {
    width: 32px;
    height: 32px;
    color: white;
  }
  
  .whychooseus .glow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    filter: blur(15px);
    opacity: 0.5;
    z-index: -1;
    transform: translateZ(-10px);
  }
  
  .whychooseus .feature-title {
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    margin-top: 1.5rem;
  }
  
  @keyframes float {
    0%,
    100% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .whychooseus .feature-card:hover .icon-wrapper {
    animation: float 3s ease-in-out infinite;
  }
  
  /* Gradient backgrounds for icons */
  .whychooseus .from-emerald-400.to-teal-600 {
    background: linear-gradient(135deg, #34d399, #0d9488);
  }
  .whychooseus .from-teal-400.to-cyan-600 {
    background: linear-gradient(135deg, #2dd4bf, #0891b2);
  }
  .whychooseus .from-cyan-400.to-emerald-600 {
    background: linear-gradient(135deg, #22d3ee, #059669);
  }
  
  @media (max-width: 768px) {
    .whychooseus .features-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .whychooseus .feature-card:hover .icon-wrapper {
      animation: none;
    }
  
    .whychooseus .feature-card:hover {
      transform: none;
    }
  }
  
  