.vps-container {
  background-color: #f8fafc;
  padding: 4rem 1rem;
}

.vps-content {
  max-width: 1200px;
  margin: 0 auto;
}

.vps-title {
  text-align: center;
  color: #008c0d;
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.features-grid {
  display: grid;
  gap: 2rem;
}

.feature-item {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
}

.icon-container {
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.icon-container svg{
  color: var(--theme-color);
  font-size: 18px;
}
.feature-icon {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
}

.feature-content {
  flex: 1;
}

.feature-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.feature-description {
  color: #4a5568;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .vps-container {
    padding: 3rem 1rem;
  }

  .vps-title {
    font-size: 1.875rem;
  }

  .feature-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .icon-container {
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .vps-container {
    padding: 4rem 1.5rem;
  }

  .features-grid {
    gap: 3rem;
  }
}

@media (min-width: 1024px) {
  .vps-container {
    padding: 4rem 2rem;
  }

  .vps-title {
    font-size: 2.5rem;
  }
}

